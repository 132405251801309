/** * 分页组件 */
<template>
  <el-pagination
    :class="['page-box', isHomePage1 ? 'isHomePage1' : '', isHomePage2 ? 'isHomePage2' : '']"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    background
    :current-page="childMsg.currentPage"
    :page-sizes="[10, 20, 30, 40]"
    :page-size="childMsg.pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    :total="childMsg.total"
  >
  </el-pagination>
</template>
<script>
export default {
  name: 'Pagination',
  props: ['childMsg', 'isHomePage1', 'isHomePage2'],
  data() {
    return {
      pageparm: {
        currentPage: this.childMsg.currentPage,
        pageSize: this.childMsg.pageSize
      }
    }
  },

  methods: {
    handleSizeChange(val) {
      /**
       * 子传父
       * 参数1 父元素方法
       * 参数2 数据
       */
      this.pageparm.pageSize = val
      this.$emit('callFather', this.pageparm)
    },
    handleCurrentChange(val) {
      /**
       * 子传父
       * 参数1 父元素方法
       * 参数2 数据
       */
      this.pageparm.currentPage = val
      this.$emit('callFather', this.pageparm)
    }
  }
}
</script>

<style>
.page-box {
  margin: 10px auto;
  position: absolute;
  left: 0;
  top: 772px;
}

.isHomePage1 {
  top: 650px !important;
}

.isHomePage2 {
  top: 640px !important;
}
/* 修改字体颜色 */
/* .el-pagination__total,.el-pagination__jump{
  color:white;
} */
</style>
